import { useEffect } from 'react';
import {useDispatch} from 'react-redux';
import config from '../../config';
import { clearUserProfileOnLogout } from '../../reduxStore/User/slice';

function LogOut() {
  const dispatch = useDispatch();
  useEffect(() => {
    const clientId = config.cognito.clientId;
    const cognitoDomain = config.cognito.domain;

    // Determine the redirect URI based on the environment
    const signlogoutUrl = window.location.hostname === 'localhost'
      ? encodeURIComponent('http://localhost:3000') // Localhost callback URL
      : encodeURIComponent('https://hivesportandsocial.ie/home'); // Deployed callback URL
    console.log('Logout URI:', signlogoutUrl);

    
    // Construct the Cognito sign-in URL
    const signOutUrl = `${cognitoDomain}/logout?client_id=${clientId}&&logout_uri=${signlogoutUrl}`;
    console.log('Redirecting to sign-out URL:', signOutUrl);
    dispatch(clearUserProfileOnLogout());
    // Redirect to Cognito hosted sign-in page
    window.location.href = signOutUrl;
  }, []);

  return null; // No need to render anything, we're redirecting
}

export default LogOut;
