import { createSlice } from '@reduxjs/toolkit';
import { fetchSportModel } from './thunks';

const initialState = {
  sports: [],
  loading: false,
  error: null,
};

export const sportsDetailsSlice = createSlice({
  name: 'sportsDetails',
  initialState,
  reducers: {
    updateSports: (state, action) => {
      console.log('updateSports:', action.payload.sports);
      state.sports = action.payload.sports;
    },
    clearSports: (state) => {
      console.log('clearSports');
      state.sports = [];
    },
    setSportsIsLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSportModel.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSportModel.fulfilled, (state, action) => {
        state.loading = false;
        state.sports = action.payload;
      })
      .addCase(fetchSportModel.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions for dispatch
export const { updateSports, clearSports, setSportsIsLoading } = sportsDetailsSlice.actions;

// Export reducer for the store
export default sportsDetailsSlice.reducer;