import React, { useState } from 'react';
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner';
import './EmailForm.css';

const EmailForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedSports, setSelectedSports] = useState([]);
  const [otherSport, setOtherSport] = useState('');
  const [preferredLocation, setPreferredLocation] = useState('');

  const sports = [
    'Pickleball','Soccer',  'Basketball', 'Badminton', 'Spikeball', 
    'Volleyball', 'Dodgeball', 'Floor Hockey', 'Other'
  ];

  const saveUserEmailURL = "https://bf6ithravj.execute-api.eu-west-1.amazonaws.com/prod/prod/emails";
    // : 'http://127.0.0.1:5000/save-user-email';

  const handleCheckboxChange = (sport) => {
    if (selectedSports.includes(sport)) {
      setSelectedSports(selectedSports.filter((s) => s !== sport));
    } else {
      setSelectedSports([...selectedSports, sport]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      setLoading(false);
      return;
    }
    console.log({
      email,
      sports: selectedSports,
      otherSport: selectedSports.includes('Other') ? otherSport : '',
      preferredLocation
    })
    try {
      const response = await axios.post(saveUserEmailURL, {
        email,
        userDetails: {
          sports: selectedSports,
          otherSport: selectedSports.includes('Other') ? otherSport : '',
          preferredLocation
        }
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'ogPHVx45jLcCUwZj9tMT7z55HkxeFyP6qDUMpyYd'
        }
      });

      if (response.status === 200) {
        setSubmitted(true);
      } else {
        setError('Failed to submit. Please try again.');
      }
    } catch (error) {
      setError('Failed to submit. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-inline">
      <h3 className="text-orange" style={{marginBottom : '25px'}}>Register your interest</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group" style={{ textAlign: 'left' }}>
          <label htmlFor="email" className="form-label text-green" style={{ display: 'inline-block', width: '75px',fontSize:'1.5rem' }}>Email</label>
          <input
            type="email"
            id="email"
            className="form-control"
            style={{ display: 'inline-block', width: 'calc(100% - 80px)', height: '40px'  }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder='Required'
          />
        </div>
        <div id="optional-form-inputs">
        {/* <h5 className="form-group-name">Optional Information</h5> */}
        <div className="form-group">
          <label className="form-label text-green">What sports would you like to play?</label>
          <div className="checkbox-group">
            {sports.map((sport) => (
              <div key={sport} className="checkbox-item">
                <input
                  type="checkbox"
                  id={sport}
                  className="form-check-input"
                  checked={selectedSports.includes(sport)}
                  onChange={() => handleCheckboxChange(sport)}
                />
                <label htmlFor={sport} className="checkbox-label" >{sport}</label>
              </div>
            ))}
          </div>
        </div>
        {selectedSports.includes('Other') && (
          <div className="form-group" style={{ textAlign: 'left' }}>
            {/* <label htmlFor="otherSport" className="form-label text-white" style={{fontSize:'1.5rem', width: '140px', display: 'inline-block'}}>Other Sport</label> */}
            <input
              type="text"
              id="otherSport"
              className="form-control  text-input"
              value={otherSport}
              style={{ display: 'inline-block', width: 'calc(100% - 0px)', height: '40px' }}
              onChange={(e) => setOtherSport(e.target.value)}
              placeholder='Write as many you like'
            />
          </div>
        )}
        <div className="form-group" >
          <label htmlFor="preferredLocation" className="form-label text-green" style={{display: 'inline-block'}}>What's your closest town?</label>
          <input
            type="text"
            id="preferredLocation"
            className="form-control text-input"
            value={preferredLocation}
            style={{ display: 'inline-block', width: 'calc(100% - 0px)', height: '40px' }}
            onChange={(e) => setPreferredLocation(e.target.value)}
          />
        </div>
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {loading ? (
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
          />
        ) : submitted ? (
          <p>Thanks for submitting your email!</p>
        ) : (
          <button type="submit" className="btn orange text-black mt-3">Submit</button>
        )}
      </form>
    </div>
  );
};

export default EmailForm;