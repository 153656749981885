import React, { useEffect,useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './components/Home/Home';
// import LogIn from './components/LogIn/LogIn';
import Navbar from './components/NavBar/NavBar';

import { selectSportsDetails } from './reduxStore/Sports/selectors';
import { useDispatch ,useSelector} from 'react-redux';
// import Footer from './components/Footer/Footer';
// import Leagues from './features/leagues/Leagues';
// import LeagueDetail from './features/leagues/LeagueDetail';
// import LeagueSignUp from './features/leagues/LeagueSignUp';
// import LeagueSignUpSuccess from './features/leagues/LeagueSignUpSuccess';
import { ToastContainer } from 'react-toastify';

function App() {

  const [loading, setLoading] = useState(true);
  const sportsLoading = useSelector(selectSportsDetails);

 const dispatch = useDispatch();
  useEffect(() => {


  }, [sportsLoading]); // Empty dependency array ensures this runs only once when the component mounts


  return (
    <div className="App">
     <Navbar />
      <main className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          {/* <Route path="/login" element={<LogIn />} />
          <Route path="/leagues" element={<Leagues />} />
          <Route path="/leagues/:id" element={<LeagueDetail />} />
          <Route path="/leagues/signup/:id" element={<LeagueSignUp />} />
          <Route path="/leagues/signup/:id/:price/:registrationTypeId" element={<LeagueSignUp />} />
          <Route path="/leagues/signup/success/:session_id" element={<LeagueSignUpSuccess />} /> */}

        </Routes>
        {/* <Footer /> */}
      </main>
      <ToastContainer />
    </div>
  );
}

export default App;