import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HiveNavBarLogo from '../LogoComponents/HiveNavBarLogo/HiveNavBarLogo';
import LogIn from '../LogIn/LogIn';
import './NavBar.css';
import LogOut from '../LogOut/LogOut';
import { selectUserProfile } from '../../reduxStore/User/selectors';


const Navbar = () => {
  const [showLogin, setShowLogin] = useState(false); // Track if the login component is visible
  const [showLogout, setShowLogout] = useState(false); // Track if the logout component is visible
  const userProfile = useSelector(selectUserProfile);

  // Function to toggle login visibility
  const handleLoginToggle = () => {
    setShowLogin(!showLogin);
  };

  const handleLogoutToggle = () => {
    setShowLogout(!showLogout);
  };

  useEffect(() => {
    console.log('userProfile', userProfile.user);
    if (userProfile.user.signedIn === true) {
    console.log('userProfile signed in ');
    }
   
  }, [userProfile]);

  return (
    <div>
      <nav className='navbar green'>
      <div>
      <HiveNavBarLogo />
      </div>
        <div className="button-group">
          {/* <Link to="/" className="btn nav-button">Home</Link> */}
          {/* {userProfile.user.signedIn  ? (
            <>
              <Link to="/schedule" className="btn nav-button">Your Schedule</Link>
              <Link to="/leagues" className="btn nav-button">Leagues</Link>
              <Link to="/accounts" className="btn nav-button">Account</Link>
              <button className='btn nav-button' onClick={handleLogoutToggle}>Logout</button>
            </>
          ) : (
            <>
              <Link to="/info/how_it_works" className="btn nav-button">How it works</Link>
              <Link to="/leagues" className="btn nav-button">Leagues</Link>
              <button className='btn nav-button' onClick={handleLoginToggle}>Login</button>
            </>
          )} */}
        </div>
      </nav>

      {/* Conditionally render the Login component */}
      {/* {showLogin && <LogIn />}
      {showLogout && <LogOut />} */}
    </div>
  );
};

export default Navbar;