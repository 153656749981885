import axios from 'axios';
import { updateUserAccessTokens,setPlayerData,setPlayerDataLoading} from '../User/slice';
import config from '../../config';


// Common constants
const cognitoDomain = 'https://auth.hivesportandsocial.ie'; // Ensure this is correct
const clientId = config.cognito.clientId;
const clientSecret = config.cognito.clientSecret;
const tokenUrl = `${cognitoDomain}/oauth2/token`;

// Encode the client_id:client_secret pair in base64
const encodedCredentials = btoa(`${clientId}:${clientSecret}`);

export const ExchangeAuthorizationCodeForTokens = (authCode) => async (dispatch) => {
    console.log('Token URL:', tokenUrl);

    const data = {
      grant_type: 'authorization_code',
      code: authCode,
      redirect_uri: 'http://localhost:3000', // Ensure this matches the redirect URI used during login
    };

    try {
      const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Basic ${encodedCredentials}`, // Include Basic Auth for client secret
        },
        body: new URLSearchParams(data),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(`Failed to exchange authorization code for tokens. Status: ${response.status}, Error: ${JSON.stringify(errorResponse)}`);
      }

      const tokens = await response.json();

      if (tokens.access_token) {
        console.log('Tokens:', tokens);
        dispatch(updateUserAccessTokens(tokens));

        // Reset the URL to remove the authorization code
        window.history.replaceState({}, document.title, window.location.pathname);
        return tokens;
      }
    } catch (error) {
      console.error('Failed to exchange authorization code for tokens', error);
    } finally {
      
    }
};

export const startTokenMonitor = (userProfile) => (dispatch) => {
    if (!userProfile || !userProfile.access_tokens || !userProfile.access_tokens.expires_at) {
        console.log('No access tokens found, skipping token monitor.');
        return;
      }

    const currentTime = Date.now();
 
    // If token is about to expire in less than 5 minutes, refresh it
    const refreshTime = userProfile.access_tokens.expires_at - currentTime - 5 * 60 * 1000;
  
    if (refreshTime > 0) {
      setTimeout(refreshAccessToken, refreshTime); // Schedule refresh
      console.log("started Token Monitor ",userProfile);
    } else {
      console.log("Refreshed Token");
      dispatch(refreshAccessToken(userProfile)); // Refresh immediately if already expired
      
    }
  };


export const refreshAccessToken = (userProfile) => async (dispatch, getState) => {
  const refreshToken = userProfile.access_tokens.refresh_token;
    console.log('refreshAccessToken:', refreshToken);
  try {
    const response = await axios.post(
      tokenUrl,
      new URLSearchParams({
        grant_type: 'refresh_token',
        client_id: clientId,
        refresh_token: refreshToken,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Basic ${encodedCredentials}`, // Include Basic Auth for client secret
        },
      }
    );

    if (response.status === 200) {
      const tokens = response.data;
      dispatch(updateUserAccessTokens(tokens)); // Dispatch the action to update the store
      console.log('Tokens refreshed:', tokens);
    } else {
      const errorResponse = response.data;
      throw new Error(`Failed to refresh tokens. Status: ${response.status}, Error: ${JSON.stringify(errorResponse)}`);
    }
  } catch (error) {
    console.error('Error refreshing tokens:', error);
    if (error.response && error.response.data && error.response.data.error === 'invalid_grant') {
      console.error('Invalid grant error: The refresh token is invalid, expired, or revoked.');
    }
  }
};

export const GetPlayerData = () => async (dispatch, getState) => {
  const { userProfile } = getState();
  const apiUrl = process.env.HIVES_SPORTS_DATA_API_URL; 
  const params = JSON.stringify({"Body":{"Action": "GetPlayerHiveData", "Parameters": { "PlayerEmail": userProfile.user.email }}});
  console.log('API URL '+apiUrl + ' Request Params '+params);
  dispatch(setPlayerDataLoading(true));
  try {
    const response = await axios.post(
      `http://127.0.0.1:5000/hive-sports-data`,
      JSON.stringify({
        Action: "GetPlayerHiveData",
        Parameters: { PlayerEmail: userProfile.user.email }
      }),
      {
        headers: {
          'Authorization': `Bearer ${userProfile.access_tokens.access_token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      const playerData = response.data;
      dispatch(setPlayerData(playerData)); // Dispatch the action to update the player_data slice
      console.log('Player data fetched:', playerData);
    } else {
      const errorResponse = response.data;
      throw new Error(`Failed to fetch player data. Status: ${response.status}, Error: ${JSON.stringify(errorResponse)}`);
    }
  } catch (error) {
    console.error('Error fetching player data:', error);
  }
};