import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';
import userProfileSlice from './reduxStore/User/slice';
import sportsDetailsSlice from './reduxStore/Sports/slice';
import leaguesDetailsSlice from './reduxStore/Leagues/slice';

// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['register'], // Exclude non-serializable key
};

// Combine your reducers
const rootReducer = combineReducers({
  userProfile: userProfileSlice,
  sports: sportsDetailsSlice,
  leagues: leaguesDetailsSlice,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware : (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'], // Ignore Persist actions
        ignoredPaths: ['register'], // Ignore register state
      },
    }),
});

export const persistor = persistStore(store);

export default store;