import React from 'react';
import logo from '../../../mediaContent/LOGO-NO-TEXT-PNG.png'; 

const HiveNavBarLogo = () => {
    return (
        <div>
        <img
              src={logo}
              alt="HiveTinyIcon"
              className="img-fluid"
              style={{ maxHeight: '40px', objectFit: 'cover' }}
            />
            <label className="logo" style={{marginLeft:'10px', fontWeight:'bold'}}>Hive</label>
        </div>
    );
};

export default HiveNavBarLogo;