import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the thunk
export const fetchSportModel = createAsyncThunk(
    'sport/fetchSportModel',
    async (userId, thunkAPI) => {
        try {
            // Determine the URI based on the environment
            let getHiveSportsDataURL = 'http://127.0.0.1:5000/get-hive-sports-data';

            //console.log('fetchSportModel: start '+ getHiveSportsDataURL);
            const response = await axios.get(`${getHiveSportsDataURL}`)
            .then((response) => {
                console.log('fetchSportModel:', response.data);
                return response.data;
            })
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);