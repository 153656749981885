import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the thunk
export const fetchLeaguesModel = createAsyncThunk(
    'league/fetchLeaguesModel',
    async (userId, thunkAPI) => {
        try {
            // Determine the URI based on the environment
            let getHiveLeaguesDataURL = 'http://127.0.0.1:5000/get-hive-leagues-data';

            console.log('fetchSportModel: start '+ getHiveLeaguesDataURL);
            // const response = await axios.get(`${getHiveLeaguesDataURL}`)
            // .then((response) => {
            //     console.log('fetchSportModel:', response.data);
            //     return response.data;
            // })
            // return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);