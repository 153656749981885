import React from 'react';
import logo from '../../../mediaContent/LOGO-NO-TEXT-PNG.png'; 
import './HiveMainLogo.css';

const HiveMainLogo = () => {
    return (
        <div className="hive-logo-container">
            <img
                src={logo}
                alt="HiveTinyIcon"
                className="hive-logo"
            />
            <div className="logo-text">
                <h1>Hive</h1>
                <h3>Sport and Social</h3>
            </div>
        </div>
    );
};

export default HiveMainLogo;
