import { createSlice } from '@reduxjs/toolkit';


const decodeToken = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: {
    user: {"name": "", "email": "","signedIn": false},
    access_tokens: {"access_token":"","id_token":"","refresh_token":"", "expires_in": 0,"token_type" :"", "expires_at": null},
    player_data: {},
    player_data_loading: false,
  },
  reducers: {
    updateUserAccessTokens: (state, action) => {
        console.log('updateUserAccessTokens:', action.payload);
        const { access_token, id_token, refresh_token,expires_in, token_type } = action.payload;
        const expiryTime = Date.now() + expires_in * 1000;
        state.access_tokens = {
          ...state.access_tokens,
          access_token,
          id_token,
          refresh_token,
          expires_in,
          token_type,
          expires_at: expiryTime
        };
        state.user.signedIn = true;
        //Set the email & username
        const decodedIdToken = decodeToken(action.payload.id_token);
        state.user.email = decodedIdToken.email;
        state.user.name = decodedIdToken.email.split('@')[0];

      },
      clearUserProfileOnLogout: (state) => {
        console.log('clearUserProfileOnLogout');
        state.access_tokens = {
          ...state.access_tokens,
          access_token: null,
          id_token: null,
          refresh_token: null,
          expires_in: 0,
          token_type: null,
          expires_at: null
        };
        state.user.signedIn = false;
        state.user.email = '';
        state.user.name = '';
        state.player_data = {};
      },
      setPlayerData: (state, action) => {
        console.log('setPlayerData:', action.payload);
        state.player_data = action.payload;
        state.player_data_loading = false;
      },
      setPlayerDataLoading: (state, action) => {
        console.log('setPlayerDataLoading:', action.payload);
        state.player_data_loading = action.payload;
      }

  },
});

// Export actions for dispatch
export const { updateUserAccessTokens,clearUserProfileOnLogout, setPlayerData,setPlayerDataLoading} = userProfileSlice.actions;

// Export reducer for the store
export default userProfileSlice.reducer;
