import { createSlice } from '@reduxjs/toolkit';
import { fetchLeaguesModel } from './thunks';
import badmintonBckGround from '../../mediaContent/mainDesigns/BADMINTON.jpg';
import basketballBckGround from '../../mediaContent/mainDesigns/BASKETBALL.jpg';
import volleyballBckGround from '../../mediaContent/mainDesigns/VOLLEYBALL.jpg';
const initialState = {
    leagueDetails: [
        { id: 1, title: '3v3 Basketball', imgSrc: basketballBckGround, location: 'Celbridge', startDate: '2025-06-01', routeLink: '/leagues/1', teamPrice: 150, individualPrice: 50 },
        { id: 2, title: 'Badminton', imgSrc: badmintonBckGround, location: 'Leixlip', startDate: '2025-04-05', routeLink: '/leagues/2', teamPrice: 100, individualPrice: 30 },
        { id: 3, title: 'Volleyball', imgSrc: volleyballBckGround, location: 'Maynooth', startDate: '2025-05-10', routeLink: '/leagues/3', teamPrice: 200, individualPrice: 40 },
    ],
    selectedLeagueId: null,
    loading: false,
    error: null,
};

export const leagueDetailsSlice = createSlice({
  name: 'leagues',
  initialState,
  reducers: {
    updateLeagues: (state, action) => {
      console.log('updateLeagues:', action.payload.Leagues);
      state.leagues = action.payload.leagues;
    },
    clearLeagues: (state) => {
      console.log('clearLeagues');
      state.leagues = [];
    },
    setSportsIsLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSelectedLeagueId: (state, action) => {
      state.selectedLeagueId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeaguesModel.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLeaguesModel.fulfilled, (state, action) => {
        state.loading = false;
        state.leagues = action.payload;
      })
      .addCase(fetchLeaguesModel.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions for dispatch
export const { updateLeagues, clearLeagues, setLeaguesIsLoading, setSelectedLeagueId } = leagueDetailsSlice.actions;

// Export reducer for the store
export default leagueDetailsSlice.reducer;