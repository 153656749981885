import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HiveMainLogo from '../../components/LogoComponents/HiveMainLogo/HiveMainLogo';
//import { LeagueCard } from '../leagues/LeagueCard';
import SportSurvey from '../../components/SportSurvey/SportSurvey';
import { useSelector } from 'react-redux';
import { selectLeaguesDetails } from '../../reduxStore/Leagues/selectors';
import EmailForm from '../../components/EmailForm/EmailForm';


const HomePage = () => {
  // Define a constant array to hold the league data
const leagues = useSelector(selectLeaguesDetails)

useEffect(() => {
    // console.log('leagues', leagues);
}, [leagues]);

  return (
    <Container fluid>
      {/* Header Section */}
      <Row className="orange text-black text-center py-3">
        <Col>
          {/* <Link to='/leagues' className='text-black' style={{ fontSize: '1.75rem',fontWeight: 'bold' }}>Register your interest now</Link> */}
        </Col>
      </Row>

      {/* Banner Section */}
      <Row className="text-center my-4">
        <Col>
          <HiveMainLogo />
        </Col>
      </Row>

      {/* About Section */}
      <Row className="text-center my-4">
        <Col lg={6} md={8} sm={10} xs={12} className="mx-auto">
          <p>Take back your evenings! Hive runs recreational sports leagues for us busy adults who want to keep active, have a laugh and meet some cool people. All while keeping it casual and low commitment.
This is what you’re looking for.</p>
        </Col>
      </Row>

      {/* Upcoming Leagues Header */}
      {/* <Row className="text-center my-4">
        <Col>
          <h2>Upcoming Leagues</h2>
        </Col>
      </Row> */}
      <Row className="text-center my-4 d-flex justify-content-center align-items-center">
      <Col md={4} className="text-center">
        <EmailForm />
        

      </Col>
    </Row>
      
      {/* Leagues Section */}
      {/* <Row className="g-4">
        {leagues.leagueDetails.map((league) => (
          <Col lg={4} md={6} sm={12} key={league.title}>
            <LeagueCard title={league.title} imgSrc={league.imgSrc} location={league.location} startDate={league.startDate} routeLink={league.routeLink}/>
          </Col>
        ))}
      </Row> */}
      {/* Sport Survey Section */}
      <Row className="my-4">
        <Col>
          {/* <SportSurvey /> */}
        </Col>
      </Row>
    </Container>
  );
};

export default HomePage;