import React, { useEffect, useState,useRef, getState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Main from '../../features/main/Main';
import {fetchSportModel} from '../../reduxStore/Sports/thunks';
import { ExchangeAuthorizationCodeForTokens, startTokenMonitor } from '../../reduxStore/User/thunks'
import { selectUserProfile } from '../../reduxStore/User/selectors';
import { GetPlayerData } from '../../reduxStore/User/thunks';


function Home() {
    const [loading, setLoading] = useState(false);
    const [tokens, setTokens] = useState(null);
    const requestMadeRef = useRef(false); 
    const dispatch = useDispatch();
    const userProfile = useSelector(selectUserProfile);
    
    useEffect(() => {
        // Example usage: exchange tokens when the component mounts
        // const authCode = new URLSearchParams(window.location.search).get('code');
        // if (authCode && !requestMadeRef.current) {
        //   setLoading(true);
        //   requestMadeRef.current = true;
        //   setTokens(dispatch(ExchangeAuthorizationCodeForTokens(authCode)));
        //   dispatch(fetchSportModel(1));

        // }
        // dispatch(startTokenMonitor(userProfile));
        // eslint-disable-next-line
      }, [dispatch, requestMadeRef,userProfile]);

      useEffect(() => {
        // if(userProfile.user.signedIn){
        //   console.log('user signed in in Home');
        //   dispatch(GetPlayerData());
        // }
      }
      , [dispatch,userProfile.user.signedIn]);
  
    return (
      <div className="Home">
       <header className="App-header">
            <Main />
        </header>
      </div>
    );
  }
  
  export default Home;
